<template>

  <div class="row" ref="authorForm">


    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Member Name"
                            name="name"
                            @keyup="generateURL"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The url"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="URL"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="job"
                  rules=""
                  name="The job title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="job title"
                            name="job"
                            fou
                            v-model="formData.job">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="website"
                  rules=""
                  name="The Email"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Email"
                            name="Email"
                            fou
                            v-model="formData.email">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="twitter"
                  rules=""
                  name="Twitter"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Twitter"
                            name="Twitter"
                            fou
                            v-model="formData.twitter">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="linked_in"
                  rules=""
                  name="Linked In"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Linked In"
                            name="Linked In"
                            fou
                            v-model="formData.linked_in">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-1  pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch color="ml-4" v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>MEMBER Image</label>
              <el-tooltip placement="right" v-if="getAuthorImageInfo()">
                <div slot="content">{{ getAuthorImageInfo() }}</div>
                <span style="margin: 0 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                          d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                      </span>
              </el-tooltip>
              <prime-uploader
                :preview-width="'200px'"
                :preview-height="'200px'"
                :elementNum="1"
                v-model="formData.image"
              >
              </prime-uploader>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/team-members/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Tooltip} from 'element-ui';
import {PrimeUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    LSwitch,
    [Tooltip.name]: Tooltip,
    PrimeUploader,
    'editor': Editor
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        url: "",
        image: "",
        email: "",
        job: "",
        twitter: "",
        linked_in: "",
        is_active: true,
        lang: 'en',
      },
    };
  },

  created() {
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Team Member";
      this.getAuthor();
    } else {
      this.editMode = false;
      this.formTitle = "Add Team Member";
      this.loader.hide();
    }

  },


  methods: {
    getAuthor() {
      let data = {
        'id': this.id,
        'lang': this.formData.lang
      };
      this.axios.post("team-members/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Team Member Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("team-members/update/" + this.id, this.formData);
        successMessage = "Team Member Updated Successfully";
      } else {
        request = this.axios.post("team-members/create", this.formData);
        successMessage = "Team Member Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/team-members/list/" + this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    getAuthorImageInfo() {
      if (this.mediaInfo && this.mediaInfo['banner_image_team_members']) {
        return this.mediaInfo["banner_image_team_members"];
      } else {
        return "";
      }
    },
    generateURL() {
      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }
      this.formData.url = newUrl;
    }

  }
}
</script>

<style>
.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0;
  padding-bottom: 3px;

}

.extra-fields > h4 {
  padding: 15px;
}
</style>
